import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
    {{'Footer.creadtedBy' | translate}} <b><a href="https://parker.no" target="_blank">Parker</a></b> {{year}}
    </span>
 
  `,
})
export class FooterComponent {
  year:any
  constructor(public translateService:TranslateService){
    this.year= new Date().getFullYear();  
  }
}

// <div class="socials">
// <a href="#" target="_blank" class="ion ion-social-github"></a>
// <a href="#" target="_blank" class="ion ion-social-facebook"></a>
// <a href="#" target="_blank" class="ion ion-social-twitter"></a>
// <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
// </div>
