<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
   
    <a class="logo"  href="pages/booking/booking-list" > <img src="/assets/images/appicon.png" alt="image" height="50" width="163" ></a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->

  <nb-select [selected]="currentLang" (selectedChange)="changeLang($event)" status="primary" style="margin-left: 20px;">
    <nb-option *ngFor="let theme of languages" [value]="theme.code"> {{ theme.title }}</nb-option>
  </nb-select>
</div>

<div class="header-container">
 
  <nb-actions size="small">

  
   
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [nbContextMenuTag]="tag"

               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
