import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators/filter';
import { CustomerService } from '../../../pages/customer/customer.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  tag = 'my-context-menu';
  user: any;
  profilePic:any
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  currentLang='no';

  
  languages = [ { title: this.translateService.instant('Lang.english'),code:"en" },{ title: this.translateService.instant('Lang.norwegian'),code:"no" } ];
  userMenu = [ { title: this.translateService.instant('UserMenu.logout'),link:'logOut' } ];
  responseUser:any
  userType:any
  id:any;
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private customerService:CustomerService,
              private router: Router,
              private breakpointService: NbMediaBreakpointsService,
              public translateService:TranslateService) {
  }

  async ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.userType=localStorage.getItem('type');    
    this.currentLang=localStorage.getItem('lang')??'no';
    this.id=localStorage.getItem('id');
    if(this.userType=='customer'){
       this.responseUser=await this.customerService.getCustomerById(Number(this.id))
      //  this.responseUser=await this.customerService.getCustomerById(Number(49))
      
    }else{
      this.responseUser=await this.customerService.getAdminById(Number(this.id))

    }

    this.translateService.use(this.currentLang)
  
    this.user=this.responseUser['data'];


    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = this.responseUser['data']);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

      this.menuService.onItemClick()
      .pipe(filter(({ tag }) => tag === this.tag))
      .subscribe(bag =>{
        if(bag.item.link=='logOut'){
          localStorage.removeItem('token')
          localStorage.removeItem('id')
          if(this.userType==='customer'){
            this.router.navigate(['auth']);
          }else{
            this.router.navigate(['auth/admin/login']);   
          }
         
        }
      } );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }
   changeLang(themeName: string) {
    this.translateService.use(themeName)
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }
  logout(){
  }
 
}
