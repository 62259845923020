import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  async getCustomerData(){
    var token:string =localStorage.getItem('token');
    var userList: any[];
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    })
    return await this.http.get<any>('https://api.parker.no/api/customers',{headers:headers}).toPromise();
  
  }

  async addCustomer(body:FormData){
    var token:string =localStorage.getItem('token');

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    })
    return await this.http.post('https://api.parker.no/api/customers',body,{headers:headers}).toPromise();
  
  }
  async editCustomer(body:FormData,id:number){
    var token:string =localStorage.getItem('token');

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    })
    return await this.http.post(`https://api.parker.no/api/customers/${id}`,body,{headers:headers}).toPromise();
  
  }
  async deleteCustomer(id:number){
    var token:string =localStorage.getItem('token');

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    })
    return await this.http.post(`https://api.parker.no/api/customers/${id}`,{'_method':"DELETE"},{headers:headers}).toPromise();
  
  }
  async getCustomerById(id:number){
    var token:string =localStorage.getItem('token');

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    })
    return await this.http.get(`https://api.parker.no/api/customers/${id}`,{headers:headers}).toPromise();
  
  }

  async getAdminById(id:number){
    var token:string =localStorage.getItem('token');

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    })
    return await this.http.get(`https://api.parker.no/api/admin/1`,{headers:headers}).toPromise();
  }

  async addCompany(body:FormData){
    var token:string =localStorage.getItem('token');

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    })
    return await this.http.post('https://api.parker.no/api/companies',body,{headers:headers}).toPromise();
  }
  async deleteCompany(id:number){
    var token:string =localStorage.getItem('token');

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    })
    return await this.http.post(`https://api.parker.no/api/companies/${id}`,{'_method':"DELETE"},{headers:headers}).toPromise();
  }

}
